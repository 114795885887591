<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          权限名称
        </span>
        <el-input class="flex-1" v-model="form.note" size="small" minlength="2" maxlength="16" show-word-limit placeholder="请输入权限名称"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          权限属性
        </span>
        <el-select class="flex-1" v-model="form.permissionAttr" placeholder="请选择" size="small">
          <el-option
            v-for="item in permissionAttrOption"
            :key="item.k"
            :label="item.v"
            :value="item.k">
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          操作标识code
        </span>
        <el-input class="flex-1" v-model="form.configKey" size="small" :disabled="!!authId" placeholder="请输入操作标识code"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          上级菜单
        </span>
        <el-popover
          v-model="treeSelectOpen"
          class="flex-1"
          placement="bottom-start"
          width="346"
          transition="el-zoom-in-top"
          trigger="click">
          <el-tree
            :data="authTree"
            ref="authTree"
            node-key="id"
            :props="defaultProps"
            @node-click="handleNodeClick"
            v-if="visible"
          >
          </el-tree>
          <div slot="reference" class="tree-select flex flex-center">
            <span class="flex-1">{{form.parentName}}</span>
            <i class="el-icon-arrow-down" style="color: #C0C4CC;"></i>
          </div>
        </el-popover>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          是否生效
        </span>
        <el-select class="flex-1" v-model="form.flag" placeholder="请选择" size="small">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label self-start">
          权限说明
        </span>
        <el-input type="textarea" class="flex-1" v-model="form.description" size="small" maxlength="255" rows="5" show-word-limit placeholder="请输入权限说明以便后续查看"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { get_dy_permission_detail, get_dy_upper_menu, save_dy_permission, update_dy_permission } from '@/api/user'
import { FormValid } from "@/utils/formValid";

const form = {
  note: '',
  parentId: -1,
  permissionAttr: '1',
  configKey: '', // 操作标识code 规则：一级菜单：模块名称+ ':manager:menu'；二级菜单：模块名称+ 二级模块名称+':menu'；其他按钮：模块名称+按钮功能：':btn'
  flag: true,
  description: ''
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    authId: {
      type: String
    },
    permissionAttrOption: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      authTree: [],
      defaultProps: {
        children: 'child',
        label: 'note'
      },
      treeSelectOpen: false,
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.authId) {
          this.getAuthDetail()
        }
        this.getUpperMenu()
      } else {
        this.form = this.$deepClone(form)
      }
    }
  },
  computed: {
    title() {
      return this.authId ? '编辑权限' : '新增权限'
    },
    checkFormList() {
      let arr = [
        {
          label: '权限名称',
          value: 'note',
          methods: ['notEmpty']
        },
        {
          label: '上级菜单',
          value: 'parentName',
          methods: ['notEmpty']
        },
        {
          label: '权限属性',
          value: 'permissionAttr',
          methods: ['notEmpty']
        },
        {
          label: '操作标识code',
          value: 'configKey',
          methods: ['notEmpty']
        }
      ]
      return arr
    }
  },
  methods: {
    getAuthDetail() {
      get_dy_permission_detail({
        id: this.authId
      }).then(res => {
        this.form = res || this.$deepClone(form)
        this.form.permissionAttr = this.form.permissionAttr + ''
        this.form.parentName = this.form.parentNote || '空'
      })
    },
    getUpperMenu() {
      get_dy_upper_menu().then(res => {
        this.authTree = res || []
        if (!this.authId) {
          this.handleNodeClick(this.authTree[0] || {})
        }
      })
    },
    handleNodeClick(data) {
      console.log(data)
      this.treeSelectOpen = false
      this.form.parentName = data.note
      this.form.parentId = data.id
    },
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      if (this.form.note.length < 2) {
        this.$message.warning('权限名称长度须在2-16位')
        return false
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      delete params.parentName
      delete params.parentNote
      console.log(params)
      this.loading = true
      if (this.authId) {
        delete params.child
        update_dy_permission(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      } else {
        save_dy_permission(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 100px;
    text-align: right;
    padding-right: 20px;
  }
}

.tree-select{
  height: 30px;
  line-height: 30px;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  padding: 0 10px 0 15px;
  font-size: 13px;
}
</style>