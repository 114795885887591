import { render, staticRenderFns } from "./system-notice.vue?vue&type=template&id=0b856340&scoped=true"
import script from "./system-notice.vue?vue&type=script&lang=js"
export * from "./system-notice.vue?vue&type=script&lang=js"
import style0 from "./system-notice.vue?vue&type=style&index=0&id=0b856340&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b856340",
  null
  
)

export default component.exports