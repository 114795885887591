<template>
  <div class="page-content">
    <div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">权限名称或说明</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入权限名称或说明"></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">权限管理</span>
        <el-button type="primary" size="small" @click="showEditDialog()">+ 新增权限</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        row-key="id"
        header-row-class-name="table-header"
        :tree-props="treeProps"
      >
        <el-table-column
          prop="note"
          label="权限名称"
          min-width="170"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="permissionAttr"
          label="权限属性"
          min-width="100"
        >
          <template slot-scope="scope">
            {{permissionAttrMap[scope.row.permissionAttr]}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="上级菜单"
          min-width="170"
        >
          <template slot-scope="scope">
            {{scope.row.parentId == -1 ? '-' : scope.row.parentNote}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="configKey"
          label="操作标识code"
          min-width="170"
        ></el-table-column>
        <el-table-column
          align="center"
          label="是否生效"
          width="100"
        >
          <template slot-scope="scope">
            <span class="point" :style="{'background': flagColor[scope.row.flag ? 1 : 2]}"></span>
            {{scope.row.flag ? '是' : '否'}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="description"
          label="权限说明"
          min-width="170"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showEditDialog(scope.row)">编辑</el-button>
            <el-button type="text" @click="showAuthDialog(scope.row)">授权</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <edit-auth-dialog :visible="editVisible" :authId="authId" :permissionAttrOption="permissionAttrOption" @close="hideEditDialog" @confirm="confirmEditDialog"></edit-auth-dialog>

    <auth-role-dialog :visible="authVisible" :authId="authId" :authName="authName" @close="hideAuthDialog"></auth-role-dialog>
  </div>
</template>

<script>
import { get_dy_permission } from '@/api/user'
import { get_key_value } from '@/api/common'
import editAuthDialog from './component/edit-auth-dialog'
import authRoleDialog from './component/auth-role-dialog'
export default {
  name: 'system-auth',
  components: {
    editAuthDialog,
    authRoleDialog
  },
  data() {
    return {
      permissionAttrOption: [],
      permissionAttrMap: {},
      flagColor: {
        1: '#67C23A',
        2: '#A4A4A4'
      },
      searchParams: {
        search: ''
      },
      list: [],
      listAll: [],
      treeProps: {
        children: 'child'
      },
      editVisible: false,
      authId: null,
      authVisible: false,
      authName: null
    }
  },
  mounted() {
    this.getList()
    this.getOption()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'DY_PERMISSION_ATTR'
      }).then(res => {
        this.permissionAttrOption = res ? res.records : []
        this.permissionAttrMap = this.$arrayToMap(this.permissionAttrOption)
      })
    },
    getList() {
      get_dy_permission().then(res => {
        if (res) {
          this.list = res
          this.listAll = this.list.concat()
        } else {
          this.list = []
        }
      })
    },
    search() {
      let list = this.listAll.concat()
      this.list = []
      const search = this.searchParams.search
      if (search) {
        list.map(o => {
          let child = (o.child || []).concat()
          const children = child.filter(v => {
            return v.note.indexOf(search) > -1 || v.description.indexOf(search) > -1
          })
          if (o.note.indexOf(search) > -1 || o.description.indexOf(search) > -1 || children.length > 0) {
            this.list.push({
              ...o,
              child: children
            })
          }
        })
      } else {
        this.list = this.listAll.concat()
      }
    },
    reset() {
      this.searchParams.search = ''
      this.list = this.listAll.concat()
    },
    showEditDialog(row) {
      if (row) {
        this.authId = row.id + ''
      } else {
        this.authId = null
      }
      this.editVisible = true
    },
    hideEditDialog() {
      this.authId = null
      this.editVisible = false
    },
    confirmEditDialog() {
      this.hideEditDialog()
      this.getList()
    },
    showAuthDialog(row) {
      this.authVisible = true
      this.authId = row.id + ''
      this.authName = row.note
    },
    hideAuthDialog() {
      this.authId = null
      this.authName = null
      this.authVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>