<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">通知管理</span>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        v-loading="loading"
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          prop="noticeName"
          label="通知名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="noticeDesc"
          label="通知说明"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="noticeSmsTemplate"
          label="短信示例"
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="短信发送人员"
        >
          <template slot-scope="scope">
          <el-select v-model="scope.row.noticeUserIdList" multiple placeholder="请选择" size="small" @change="changeStatus(scope.row)" class="flex-1" style="width:100%">
            <el-option v-for="(o,i) in userlist" :key="i" :label="o.realName" :value="o.id"></el-option>
          </el-select>
            <!-- {{statusMap[scope.row.status]}} -->
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { get_YhjNoticeList, post_updateYhjNotice } from '@/api/base'
import { get_user_list } from '@/api/user'

export default {
  name: 'marketer-list',
  components: {
  },
  data() {
    return {
      list: [],
      userlist: [],
      loading: true
    }
  },
  computed: {
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    getList() {
      get_YhjNoticeList({}).then(res => {
        console.log(res)
        this.list = res ? res : []
        this.loading = false
      })
    },
    async getUserList() {
      const res = await get_user_list({pageNo: 1,size: 999})
      console.log(res)
      this.userlist = res ? res.records : []
      this.getList()
    },
    changeStatus(val) {
      post_updateYhjNotice({
        noticeType: val.noticeType,
        noticeUserIdList: val.noticeUserIdList
      }).then(() => {
        this.$message.success('修改成功')
        this.getList()
      })
    },
    turnTo() {
      this.$router.push({ name: 'system-notice'})
    }
  }
}
</script>

<style lang="scss" scoped>
.select1 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #E6F8E6;
        color: #64A264;
        border-width: 0;
      }
      .el-select__caret {
        color: #64A264;
      }
    }
  }
}  
.select2 {
  ::v-deep {
    .el-input {
      .el-input__inner {
        background: #f7eaea;
        color: #ba5454;
        border-width: 0;
      }
      .el-select__caret {
        color: #ba5454;
      }
    }
  }
}  
</style>